<template>
  <dialogue-modal
    v-if="priceGroupItemToDelete"
    title="Are you sure you want to delete?"
    :message="priceGroupItemToDelete.productName"
    :close-popup="hideDeleteModal"
    :buttons="buttons"
  />
  <page-title pageTitle="Customer Price Group" :title="priceGroupName" />

  <div class="row pb-4 gy-3">
    <div class="col-sm-auto">
      <button
        v-if="id > 1"
        class="btn btn-primary mt-2 mt-md-0"
        @click="addPriceGroupItem(id)"
      >
        <i class="las la-plus me-1"></i> Add New
      </button>
      <router-link
        v-if="id > 1"
        class="btn btn-primary mx-2 mt-2 mt-md-0"
        :to="`/priceGroups/${id}/new/productGroup`"
      >
        <i class="las la-plus me-1"></i> Add By Product Group
      </router-link>
      <button v-if="id > 1" class="btn btn-warning mt-2 mt-md-0" disabled>
        <i class="las ri-mark-pen-line me-1"></i> Edit Price By Product Group
      </button>
      <!--      <router-link-->
      <!--        v-if="id > 1"-->
      <!--        class="btn btn-warning mt-2 mt-md-0"-->
      <!--        :to="`/priceGroups/edit/productGroup/${id}`"-->
      <!--      >-->
      <!--        <i class="las ri-mark-pen-line me-1"></i> Edit Price By Product Group-->
      <!--      </router-link>-->
      <button v-else class="btn btn-primary" @click="$router.go(-1)">
        <i class="las la-arrow-left me-1"></i> Back
      </button>
    </div>

    <div class="col-sm-auto ms-auto">
      <div class="d-flex gap-3">
        <div class="search-box">
          <input
            type="text"
            class="form-control"
            id="searchMemberList"
            placeholder="Search for Result"
            v-model="searchQuery"
            @input="goToPage(1)"
          />
          <i class="las la-search search-icon"></i>
        </div>
      </div>
    </div>

    <div class="col-sm-auto">
      <div class="d-flex input-group">
        <label class="input-group-text" for="inputGroupSelect01">Show</label>
        <select
          class="form-select"
          id="resultsPerPage"
          v-model="resultsPerPage"
          @change="goToPage(1)"
        >
          <option
            v-for="option in resultsPerPageOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div v-if="data.length > 0" class="">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <table-component
              :headers="headers"
              :data="formattedData"
              :actions="actions"
              :delete-selected="deleteSelectedPriceGroupItems"
              :offset="(currentPage - 1) * resultsPerPage"
              @sort="goToPage"
            />
          </div>
        </div>
      </div>
    </div>
    <pagination-component
      :rows-loaded="data.length"
      :current-page="currentPage"
      :num-pages="numPages"
      :total-rows="totalRows"
      :go-to-page="goToPage"
    />
  </div>

  <div v-else class="row">
    <div class="col-xl-12">
      <div class="card">
        <LoadingModal v-if="loading" />
        <div v-else class="card-body">
          {{ error }}
        </div>
        <div class="gap-2 mt-4 card-body">
          <button
            type="button"
            class="btn btn-light"
            @click="this.$router.go(-1)"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import TableComponent from "@/components/utils/Table";
import { SETTINGS } from "@/constants/config.js";
import { CONSTANTS } from "@/constants/constants.js";
import LoadingModalComponent from "@/components/utils/LoadingModal";
import PaginationComponent from "@/components/utils/paginationComponent";
import DialogueModal from "@/components/utils/modals/DialogueModal";

export default {
  name: "AllPriceGroupsView",
  components: {
    DialogueModal,
    PaginationComponent,
    LoadingModal: LoadingModalComponent,
    PageTitle: PageTitleComponent,
    TableComponent: TableComponent,
  },
  data() {
    return {
      id: -1,
      searchQuery: "",
      resultsPerPage: SETTINGS.resultsPerPage,
      priceGroupItemToDelete: null,
    };
  },
  computed: {
    headers() {
      return CONSTANTS.PRICE_GROUP_ITEM_HEADERS;
    },
    formattedData() {
      const allPriceGroupItems = this.$store.getters.priceGroup;
      return allPriceGroupItems.map((priceGroupItem) => ({
        ...priceGroupItem,
        costPrice: this.priceFormat(priceGroupItem.costPrice),
        sellingPrice: this.priceFormat(priceGroupItem.sellingPrice),
        updatedPrice: this.priceFormat(priceGroupItem.updatedPrice),
        suggestedRetailPrice: this.priceFormat(
          priceGroupItem.suggestedRetailPrice
        ),
        updatedSuggestedRetailPrice: this.priceFormat(
          priceGroupItem.updatedSuggestedRetailPrice
        ),
      }));
    },
    data() {
      return this.$store.getters.priceGroup;
    },
    priceGroupName() {
      if (this.$store.getters.priceGroupSummary.name) {
        return this.$store.getters.priceGroupSummary.name;
      }
      return "Customer Price Group";
    },
    currentPage() {
      return this.$store.getters.priceGroupsCurrentPage;
    },
    numPages() {
      return this.$store.getters.priceGroupsNumPages;
    },
    totalRows() {
      return this.$store.getters.priceGroupsTotalRows;
    },
    resultsPerPageOptions() {
      const options = CONSTANTS.RESULTS_PER_PAGE_OPTIONS;
      if (!options.includes(this.resultsPerPage)) {
        options.unshift(this.resultsPerPage);
      }
      return options;
    },
    actions() {
      if (this.$store.getters.priceGroupSummary.id > 1) {
        return {
          Edit: (priceGroupItem) => this.editPriceGroupItem(priceGroupItem.id),
          Delete: (priceGroupItem) => this.showDeleteModal(priceGroupItem),
        };
      }
      return null;
    },
    error() {
      return this.$store.getters.priceGroupsError;
    },
    loading() {
      return this.$store.getters.priceGroupsLoading;
    },
    buttons() {
      return {
        Yes: {
          class: "btn-danger",
          function: () => this.deletePriceGroupItem(),
        },
        No: {
          class: "btn-light px-3",
          function: () => this.hideDeleteModal(),
        },
      };
    },
  },
  methods: {
    priceFormat(price) {
      return `$${price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`.replace("$-", "-$");
    },
    async goToPage(page, sortBy = null) {
      let filter = {
        sortingList: sortBy,
        currentPage: page,
        resultsPerPage:
          this.resultsPerPage === "ALL" ? this.totalRows : this.resultsPerPage,
        searchQuery: this.searchQuery,
      };
      await this.$store.dispatch("getPriceGroupItems", { id: this.id, filter });
    },
    addPriceGroupItem(id) {
      this.$router.push(`/priceGroups/${id}/add`);
    },
    editPriceGroupItem(id) {
      this.$router.push(`/priceGroups/${this.id}/edit/${id}`);
    },
    showDeleteModal(priceGroupItem) {
      this.priceGroupItemToDelete = priceGroupItem;
    },
    async deletePriceGroupItem() {
      if (this.priceGroupItemToDelete) {
        await this.$store.dispatch(
          "deletePriceGroupItem",
          this.priceGroupItemToDelete.id
        );
        await this.goToPage(1);
        this.hideDeleteModal();
      } else {
        alert("There has been an error");
      }
    },
    async deleteSelectedPriceGroupItems(priceGroupItems) {
      await this.$store.dispatch(
        "deleteSelectedPriceGroupItems",
        priceGroupItems
      );
      await this.goToPage(1);
    },
    hideDeleteModal() {
      this.priceGroupItemToDelete = null;
    },
  },
  async created() {
    this.id = parseInt(this.$route.params.id);
    await this.$store.dispatch("getPriceGroup", this.id);
    let filter = {
      resultsPerPage: SETTINGS.resultsPerPage,
    };
    await this.$store.dispatch("getPriceGroupItems", { id: this.id, filter });
  },
  beforeUnmount() {
    console.log("All Price Groups Destroyed");
  },
};
</script>

<style scoped></style>
